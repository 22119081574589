import capitalize from "lodash/capitalize";
import styled from "styled-components";

import AnchoredPopover from "app/components/shared/Popover/anchored";
import FriendlyTime from "app/components/shared/FriendlyTime";
import { getDateString } from "app/lib/date";
import Emojify from "app/components/shared/Emojify";
import ExecutionDrawerButton from "../../execution/Row/ExecutionDrawerButton";
import Icon from "app/components/shared/Icon";

export type ActivityProps = {
  id: string | number;
  name: string;
  duration: number;
  result: string;
  date: string;
  url: string;
  turboFrameSrc?: string;
  humanDuration: string;
  createdAt: string;
  runId?: string;
  branch: string | null | undefined;
  buildNumber: string | null | undefined;
};

type Props = {
  activity: ActivityProps;
  height: number;
};

const RESULT_COLOUR = {
  passed: "green",
  failed: "red",
};

const StyledAnchoredPopover = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 1px;
  max-width: 12px;

  > a {
    height: 100%;
  }
`;

const StyledBar = ({ barColour }: { barColour: string }) => (
  <span
    data-testid="styled-bar"
    className={`w-full h-full transition-height duration-200 ease-in rounded-t-[2px] block ${barColour}`}
  />
);

const Bar = ({ activity, height }: Props) => {
  const barHeight = Math.round(height * 100.0);

  const colour = RESULT_COLOUR[activity.result];
  const barColour = colour ? `ta-bg-${colour} hover-ta-bg-${colour}` : "bg-gray";

  const linkLabel = `Go to ${activity.name}. ${capitalize(
    activity.result,
  )} on ${getDateString(activity.createdAt)} — ${activity.humanDuration}`;

  const button = activity.turboFrameSrc ? (
    <ExecutionDrawerButton
      label={linkLabel}
      url={activity.turboFrameSrc}
      classes="no-title-tooltip w-full h-full relative"
    >
      <StyledBar barColour={barColour} />
    </ExecutionDrawerButton>
  ) : (
    <a
      aria-label={linkLabel}
      href={activity.url}
      className="block relative h-full w-full no-title-tooltip"
    >
      <StyledBar barColour={barColour} />
    </a>
  );

  return (
    <StyledAnchoredPopover
      as={AnchoredPopover}
      className="flex"
      data-testid="bar"
      style={{
        height: `${barHeight}%`,
      }}
      width={300}
    >
      {button}

      <div className="flex flex-col gap-1 px-2 py-1">
        <span className="block font-medium break-words">
          <Emojify text={activity.name} />
        </span>

        {activity.buildNumber && (
          <div className="dark-gray small">Build #{activity.buildNumber}</div>
        )}

        {activity.branch && (
          <div className="dark-gray small">
            <Icon icon="custom/outline/14x/branch" style={{ height: 14, width: 14 }} />{" "}
            {activity.branch}
          </div>
        )}

        <span className="block small dark-gray">
          {capitalize(activity.result)}{" "}
          <FriendlyTime value={activity.createdAt} clickable={false} /> - {activity.humanDuration}
        </span>
      </div>
    </StyledAnchoredPopover>
  );
};

export default Bar;
