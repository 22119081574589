import { BuildView } from "..";

export function urlForView(
  buildPath: string,
  view: BuildView,
  params?: {
    stepUuid?: string | null;
    jobUuid?: string | null;
  },
): string {
  const views = {
    [BuildView.Summary]: "summary/annotations",
    [BuildView.Steps]: "steps",
    [BuildView.Canvas]: "canvas",
    [BuildView.Table]: "table",
    [BuildView.Waterfall]: "waterfall",
    [BuildView.Tests]: "tests",
  };

  if (!views[view]) {
    throw new Error(`Unknown view: ${view}`);
  }

  const pathname = `${buildPath}/${views[view]}`;
  if (params?.jobUuid) {
    return resolveJobUrl(params.jobUuid, pathname);
  }

  if (params?.stepUuid) {
    return resolveStepUrl(params.stepUuid, pathname);
  }

  return pathname;
}

/**
 * Resolve a job URL based on the current location or a custom pathname.
 */
export function resolveJobUrl(jobUuid: string, pathname = window.location.pathname) {
  return `${pathname}?jid=${jobUuid}`;
}

/**
 * Resolve a step URL based on the current location or a custom pathname.
 */
export function resolveStepUrl(stepUuid: string, pathname = window.location.pathname) {
  return `${pathname}?sid=${stepUuid}`;
}
