import FriendlyTime from "app/components/shared/FriendlyTime";
import React from "react";

export interface TestStateChangedEvent {
  uuid: string;
  type: "test_state_changed";
  timestamp: Date;
  data: {
    actor_system?: string;
    actor_user?: {
      name: string;
    };
    new_state: string;
    old_state: string;
  };
}

const TestStateChanged: React.FC<{ event: TestStateChangedEvent }> = ({ event }) => {
  const { new_state, actor_user, actor_system, old_state } = event.data;
  return (
    <div className="flex">
      <div className="grow">
        Test set to {new_state}
        {old_state && ` from ${old_state}`}
        {actor_user && ` by ${actor_user.name}`}
        {actor_system && ` via ${actor_system}`}.
      </div>
      <div className="justify-self-end">
        <FriendlyTime className="dark-gray" value={event.timestamp} />
      </div>
    </div>
  );
};

export default TestStateChanged;
